export default [
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
  },
  {
    key: 'itemCode',
    label: 'field.itemCode',
  },
  {
    key: 'name',
    label: 'field.itemName',
  },
  {
    key: 'company',
    label: 'field.company',
  },
  {
    key: 'department',
    label: 'field.department',
  },
  {
    key: 'quantity',
    label: 'field.quantityInHand',
  },
  {
    key: 'lockQuantity',
    label: 'field.lockQuantity',
  },
  {
    key: 'reorderLevel',
    label: 'field.reorderLevel',
  },
  {
    key: 'itemCategory',
    label: 'field.itemCategory',
  },
  {
    key: 'itemSection',
    label: 'field.itemSection',
  },
  {
    key: 'itemType',
    label: 'field.itemType',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
  {
    key: 'updatedAt',
    label: 'field.updateDate',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
];
