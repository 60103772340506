<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      @search="search"
      @reset="reset"
      @toggle-archived="toggleArchived"
      :fields="searchFields"
      show-toggle-archived
      :archived="params.archived"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #departmentId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-async-single-select
              v-model="params[item.field.key]"
              :query="
                params.companyId
                  ? [
                      {
                        searchby: 'company_id',
                        searchoperator: '=',
                        search: params.companyId,
                      },
                    ]
                  : []
              "
              :ref="item.field.key"
              :name="item.field.key"
              :repository="item.field.repository"
              :selection-key="item.field.selectionKey"
              :selection-label="item.field.selectionLabel"
            ></n-async-single-select>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', permissionType)">
            <b-button
              variant="primary"
              :to="{ name: `create-${permissionType}` }"
              >{{ $t("button.create") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-view-table
          @destroy="destroy"
          @restore="restore"
          :fields="viewTableFields"
          :type="permissionType"
          ref="viewTable"
          deletable
          :archived="params.archived"
        >
          <template #cell(itemCategory)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-item-category',
                params: { id: data.item.itemCategory.id },
              }"
              v-if="data.item.itemCategory"
            >
              {{ data.item.itemCategory.name }}
            </b-link>
          </template>
          <template #cell(itemSection)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-item-section',
                params: { id: data.item.itemSection.id },
              }"
              v-if="data.item.itemSection"
            >
              {{ data.item.itemSection.name }}
            </b-link>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(company)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-company',
                params: { id: data.item.company.id },
              }"
              v-if="data.item.company"
            >
              {{ data.item.company.name }}
            </b-link>
          </template>
          <template #cell(department)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-department',
                params: { id: data.item.department.id },
              }"
              v-if="data.item.department"
            >
              {{ data.item.department.name }}
            </b-link>
          </template>
          <template #cell(manager)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-employee',
                params: { id: data.item.manager.id },
              }"
              v-if="data.item.manager"
            >
              {{ data.item.manager.name }}
            </b-link>
          </template>
          <template #cell(position)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-position',
                params: { id: data.item.position.id },
              }"
              v-if="data.item.position"
            >
              {{ data.item.position.name }}
            </b-link>
          </template>
          <template #cell(itemType)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-item-type',
                params: { id: data.item.itemType.id },
              }"
              v-if="data.item.itemType"
            >
              {{ data.item.itemType.name }}
            </b-link>
          </template>
          <template #cell(lockQuantity)="data">
            {{ data.item.quantity - data.item.availableQuantity }}
          </template>
        </n-view-table>
        <n-table
          @sort-changed="sortChanged"
          @view="view"
          @destroy="destroy"
          @restore="restore"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :type="permissionType"
          :view-modal="true"
          deletable
          :archived="params.archived"
        >
          <template #cell(itemCategory)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-item-category',
                params: { id: data.item.itemCategory.id },
              }"
              v-if="data.item.itemCategory"
            >
              {{ data.item.itemCategory.name }}
            </b-link>
          </template>
          <template #cell(itemSection)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-item-section',
                params: { id: data.item.itemSection.id },
              }"
              v-if="data.item.itemSection"
            >
              {{ data.item.itemSection.name }}
            </b-link>
          </template>
          <template #cell(itemType)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-item-type',
                params: { id: data.item.itemType.id },
              }"
              v-if="data.item.itemType"
            >
              {{ data.item.itemType.name }}
            </b-link>
          </template>
          <template #cell(company)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-company',
                params: { id: data.item.company.id },
              }"
              v-if="data.item.company"
            >
              {{ data.item.company.name }}
            </b-link>
          </template>
          <template #cell(department)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-department',
                params: { id: data.item.department.id },
              }"
              v-if="data.item.department"
            >
              {{ data.item.department.name }}
            </b-link>
          </template>
          <template #cell(quantity)="data">
            <b-link class="link-underline" @click="viewStock(data.item)">
              {{ data.item.quantity }}
            </b-link>
          </template>
          <template #cell(lockQuantity)="data">
            <b-link class="link-underline" @click="viewStockRequest(data.item)">
              {{ data.item.quantity - data.item.availableQuantity }}
            </b-link>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
    <stock-modal ref="stockBinModal" @refresh="getData"></stock-modal>
    <stock-request-modal ref="requestModal"></stock-request-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import NViewTable from "@/components/NViewTable";
import TableFields from "./tableFields";
import ArchivedTableFields from "./archivedTableFields";
import ViewTableFields from "./viewTableFields";
import ViewArchivedTableFields from "./viewArchivedTableFields";
import SearchInputs from "./searchInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import StockModal from "./stockModal/Modal";
import StockRequestModal from "./stockRequestModal/Modal";

const ItemRepository = Repository.get("item");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    NViewTable,
    vSelect,
    NAsyncSingleSelect,
    StockModal,
    StockRequestModal,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        companyId: Number(this.$route.query.companyId) || null,
        departmentId: Number(this.$route.query.departmentId) || null,
        itemCategoryId: Number(this.$route.query.itemCategoryId) || null,
        itemSectionId: Number(this.$route.query.itemSectionId) || null,
        itemTypeId: Number(this.$route.query.itemTypeId) || null,
        unitId: Number(this.$route.query.unitId) || null,
        itemCode: this.$route.query.itemCode || null,
        name: this.$route.query.name || null,
        fromQuantity: this.$route.query.fromQuantity || null,
        toQuantity: this.$route.query.toQuantity || null,
        fromReorderLevel: this.$route.query.fromReorderLevel || null,
        toReorderLevel: this.$route.query.toReorderLevel || null,
        stockType: this.$route.query.stockType || null,
        isEnable: this.$route.query.isEnable || null,
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        archived: this.$route.query.archived == "true" ? true : false || false,
      },
    };
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.companyId"(newValue, oldValue) {
      this.$refs.departmentId.clear();
    },
  },
  mounted() {
    if (this.params.archived) {
      this.fields = [...ArchivedTableFields];
      this.viewTableFields = [...ViewArchivedTableFields];
    }
    this.getData();
  },
  methods: {
    view(item) {
      this.$refs.viewTable.view(item);
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.params.page = 1;
      this.$refs.search.reset();
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.params,
        searchFields: this.searchFields,
      };
      ItemRepository.index(params)
        .then((response) => {
          const data = response.data.data;
          data.list.map((item) => {
            if (item.quantity == 0) {
              item._rowVariant = "danger";
            } else if (item.quantity <= item.reorderLevel) {
              item._rowVariant = "warning";
            }

            return item;
          });
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewStock(item) {
      this.$refs.stockBinModal.show({
        ...item,
      });
    },
    viewStockRequest(item) {
      this.$refs.requestModal.show({
        ...item,
      });
    },
    destroy(id) {
      this.loading = true;
      ItemRepository.destroy(id)
        .then((response) => {
          this.$refs.viewTable.hide();
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    restore(id) {
      this.loading = true;
      ItemRepository.restore(id)
        .then((response) => {
          this.$refs.viewTable.hide();
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleArchived() {
      this.params.archived = !this.params.archived;
      if (this.params.archived) {
        this.fields = [...ArchivedTableFields];
        this.viewTableFields = [...ViewArchivedTableFields];
      } else {
        this.fields = [...TableFields];
        this.viewTableFields = [...ViewTableFields];
      }

      this.reset();
    },
  },
  setup() {
    const fields = TableFields;
    const viewTableFields = ViewTableFields;
    const searchFields = SearchInputs;
    const permissionType = "item";
    return {
      fields,
      viewTableFields,
      searchFields,
      permissionType,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
