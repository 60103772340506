export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'image',
    label: 'field.image',
    thClass: 'text-left',
    tdClass: 'text-left',
    type: 'image',
  },
  {
    key: 'itemCode',
    sortable: true,
    sortField: 'itemCode',
    label: 'field.itemCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.itemName',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantity',
    sortable: true,
    sortField: 'quantity',
    label: 'field.quantityInHand',
  },
  {
    key: 'lockQuantity',
    label: 'field.lockQuantity',
  },
  {
    key: 'reorderLevel',
    label: 'field.reorderLevel',
  },
  {
    key: 'company',
    sortable: true,
    sortField: 'companyId',
    label: 'field.company',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'department',
    sortable: true,
    sortField: 'departmentId',
    label: 'field.department',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'itemCategory',
    sortable: true,
    sortField: 'itemCategoryId',
    label: 'field.itemCategory',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'itemSection',
    sortable: true,
    sortField: 'itemSectionId',
    label: 'field.itemSection',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'itemType',
    sortable: true,
    sortField: 'itemTypeId',
    label: 'field.itemType',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updateDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
